import React, { useEffect, useState } from "react";

import Elearning from './AllPages/E-Learning'
import Adventure from './AllPages/Adventure'
import ParentsFinds from './AllPages/ParentsFindsKidskite'
import UnlockSection from './AllPages/UnlockEndless'
import YTVideo from './AllPages/YoutubeContent'
import Footer from './AllPages/Footer'
import YTmobile from './AllPages/YTmobile'
import Mobilefooter from './AllPages/MobileFooter'

const AllPage = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div>
      <Elearning />
      <Adventure />
      {/* <iframe
        style={{ width: "100%", height: "475px", border: "none" ,  }}
        src="https://beta-api.gameskite.com/adv/gameIframe.html?partner=bluefly&showBackButton=false"
        title="Game Iframe" scrolling="no"
      ></iframe> */}
      <ParentsFinds />
      {/* <iframe
        style={{ width: "100%", height: "475px", border: "none" ,  }}
        src="https://beta-api.gameskite.com/adv/gameIframe.html?partner=bluefly&showBackButton=false"
        title="Game Iframe" scrolling="no"
      ></iframe> */}
      <UnlockSection />
      {windowWidth <= 820  ? <YTmobile /> : <YTVideo />}
      {windowWidth <= 820  ? <Mobilefooter /> : <Footer />}
      {/* <Footer /> */}
    </div>
  );
};

export default AllPage;
